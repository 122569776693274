import { StoreProvider } from "easy-peasy";

import store from "src/store";
import AppRoutes from "src/routes/appRoutes";
import { StyledEngineProvider } from "@mui/styled-engine";

const App = () => {
	return (
		<StyledEngineProvider injectFirst>
			<StoreProvider store={store}>
				<AppRoutes />
			</StoreProvider>
		</StyledEngineProvider>
	);
};

export default App;
